<template>
  <div class="wrap">
    <Headers></Headers>
    <div class="banner">
      <img v-if="clientWidth > 750" src="@/assets/PC/paper/banner_pc.png" alt="">
      <img v-else src="@/assets/PC/paper/banner_m.png" alt="">
    </div>
    <div v-if="paperShow">
      <div class="paper module1">
        <div class="paper-title">活动介绍</div>
        <div class="paper-introduction">
          <p>作为世界知名的工业数字化解决方案专家，AVEVA 自 1967 年成立至今已服务油气、石化、化工、电力、造船、冶金、水泥、环保、造纸、制药、水处理、半导体等行业近 60
            年，凭借先进的产品技术、丰富的应用经验、不断迭代的研发创新，AVEVA 已成为工业领域数字化转型最值得信赖的合作伙伴之一。</p>
          <p>AVEVA 服务中国广大用户近 40 年来，已和各行业建立起稳固的客户基础和长期合作伙伴关系。随着用户群体的不断增加及应用的逐步深入，以及近年来行业发展的新趋势和需求多样性，为了进一步推动 AVEVA
            解决方案的应用创新，加强行业用户之间的合作和交流，提供良好的各行业用户间的交流环境，AVEVA 发起“2023 年度 AVEVA 剑维软件中国区工业数字化先锋论文集”征集活动。</p>
        </div>
        <div class="paper-box-btn flex0">
          <button type="button" class="paper-btn" @click="showForm"><span>提交论文</span> <img
              src="@/assets/PC/paper/icon_next.png" alt=""></button>
        </div>
      </div>
      <div class="module2">
        <div class="paper">
          <div class="paper-title">征稿方向</div>
          <div class="paper-tab-box">
            <div class="paper-tab" :class="currentIndex == index ? 'tab-active' : ''" v-for="(item, index) in tabList"
              :key="index" @click="clickTab(item, index)">{{ item.title }}</div>
          </div>
          <div class="paper-tab-content-box">
            <div class="paper-tab-content-left">
              <img :src="tabObj.img" v-if="clientWidth > 750" alt="">
              <img :src="tabObj.img_m" v-else alt="">
            </div>
            <div class="paper-tab-content-right">
              <div class="paper-tab-content-right-title">{{ tabObj.title1 }}</div>
              <div class="paper-tab-content-right-list" v-for="(item, index) in tabObj.list1" :key="index">
                <span>{{ item.title }}</span>
                <span v-if="item.more1">{{ item.more1 }}</span>
                <span v-if="item.more2">{{ item.more2 }}</span>
              </div>
              <div class="paper-tab-content-right-title paper-tab-content-right-top">{{ tabObj.title2 }}</div>
              <div class="paper-tab-content-right-list" v-for="(item, index) in tabObj.list2" :key="index + 20">
                <span>{{ item.title }}</span>
                <span v-if="item.more1">{{ item.more1 }}</span>
                <span v-if="item.more2">{{ item.more2 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module3">
        <div class="paper">
          <div class="paper-title">稿件要求</div>
          <div class="paper-numer" v-if="clientWidth > 750">
            <div>稿件应具有学术性、先进性和实用性，论点明确、论据可靠、数据准确、逻辑严蓮、层次分明、文字精练，并且校对清楚，标点符号必须使用准确。</div>
            <div>稿件的基本要素齐全，包括标题、作者单位、作者姓名、摘要、关键词、参考文献等。</div>
            <div>参选稿件应为原创，并保证文章版权的独立性，无抄袭、署名排序无争议。</div>
            <div>论文内容不得涉及国家秘密或泄露企业商业秘密。</div>
            <div>论文内所使用的 AVEVA产品名应以上述最新命名为准。</div>
          </div>
          <div class="paper-numer-m" v-else>
            <div class="paper-numer-m-box">
              <img src="@/assets/PC/paper/icon_01.png" alt="">
              <div class="paper-numer-m-text">
                <span>稿件应具有学术性、先进性和实用性，论点明确、论据可靠、数据准确、逻辑严蓮、层次分明、文字精练，并且校对清楚，标点符号必须使用准确。</span>
                <div></div>
              </div>
            </div>
            <div class="paper-numer-m-box">
              <img src="@/assets/PC/paper/icon_02.png" alt="">
              <div class="paper-numer-m-text">
                <span>稿件的基本要素齐全，包括标题、作者单位、作者姓名、摘要、关键词、参考文献等。</span>
                <div></div>
              </div>
            </div>
            <div class="paper-numer-m-box">
              <img src="@/assets/PC/paper/icon_03.png" alt="">
              <div class="paper-numer-m-text">
                <span>参选稿件应为原创，并保证文章版权的独立性，无抄袭、署名排序无争议。</span>
                <div></div>
              </div>
            </div>
            <div class="paper-numer-m-box">
              <img src="@/assets/PC/paper/icon_04.png" alt="">
              <div class="paper-numer-m-text paper-numer-m-top">
                <span>论文内容不得涉及国家秘密或泄露企业商业秘密。</span>
                <div></div>
              </div>
            </div>
            <div class="paper-numer-m-box paper-numer-m-box-center">
              <img src="@/assets/PC/paper/icon_05.png" alt="">
              <div class="paper-numer-m-text">
                <span>论文内所使用的 AVEVA 产品名应以上述最新命名为准。</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="paper module4">
        <div class="paper-title">投稿方式</div>
        <div class="paper-contribute">
          <img src="@/assets/PC/paper/icon_left.png" v-if="clientWidth > 750" alt="">
          <div class="paper-contribute-text" v-if="clientWidth > 750">
            征文作者请于 2023 年 9 月 25 日前提交征文电子版（DOC 格式）。上传文件时，请在系统内详细填写作者简介和联系方式。<br>
            论文入选通知函将于 2023 年 10 月 20 日前向作者发出。
          </div>
          <div class="paper-contribute-text-m" v-else>
            <p>征文作者请于 2023 年 9 月 25 日前提交征文电子版（DOC 格式）。上传文件时，请在系统内详细填写作者简介和联系方式。</p>
            <p>论文入选通知函将于 2023 年 10 月 20 日前向作者发出。</p>
          </div>
          <img src="@/assets/PC/paper/icon_right.png" v-if="clientWidth > 750" alt="">
        </div>
      </div>
      <div class="paper module5">
        <div class="paper-title">论文评选</div>
        <div class="paper-select-bigBox">
          <div class="paper-select-box">
            <div class="paper-select">评选工作将由 AVEVA 组织技术专家委员会进行评选，入选论文将收录于《2023 年度 AVEVA 剑维软件中国区工业数字化先锋论文集》。</div>
            <div class="paper-select">收录论文第一作者将获得专属礼品，暂定与入选通知函同期发放。</div>
            <div class="paper-select">论文集将于论剑 2023 – 剑维软件中国用户大会（暂定 11 月份）正式印刷并发布。</div>
          </div>
          <div class="paper-order-box">
            <div class="paper-order-line"></div>
            <div class="paper-order-index">1</div>
            <div class="paper-order-line"></div>
            <div class="paper-order-index">2</div>
            <div class="paper-order-line"></div>
            <div class="paper-order-index">3</div>
            <div class="paper-order-line"></div>
          </div>
        </div>

        <div class="paper-pdf-detail">
          <span>* 更多投稿格式须知，请下载征集函查看详细内容：</span>
          <a download="下载说明"
            href="https://aveva.blob.core.chinacloudapi.cn/resource/File/%E5%85%B3%E4%BA%8E%E5%BE%81%E9%9B%862023%E5%B9%B4%E5%BA%A6AVEVA%E5%89%91%E7%BB%B4%E8%BD%AF%E4%BB%B6%E4%B8%AD%E5%9B%BD%E5%8C%BA%E5%B7%A5%E4%B8%9A%E6%95%B0%E5%AD%97%E5%8C%96%E5%85%88%E9%94%8B%E8%AE%BA%E6%96%87%E9%9B%86%E7%9A%84%E9%80%9A%E7%9F%A5.pdf">《“2023
            年度 AVEVA 剑维软件中国区工业数字化先锋论文集”征集通知》</a>
        </div>
        <div class="paper-box-btn flex0">
          <button type="button" class="paper-btn" @click="showForm"><span>提交论文</span> <img
              src="@/assets/PC/paper/icon_next.png" alt=""></button>
        </div>
      </div>
      <div class="paper-footer flex0">
        <span>如您有任何疑问，请联系 AVEVA 剑维软件市场部：</span>
        <div>
          <img src="@/assets/PC/paper/icon_phone.png" alt="">
          <span class="paper-footer-phone">方慧晶 13262736263</span>
          <img src="@/assets/PC/paper/icon_email.png" alt="">
          <a href="mailto:echo.fang@aveva.com">echo.fang@aveva.com</a>
        </div>
      </div>
    </div>
    <div class="register" v-else>
      <div class="register-body">
        <div class="register-content" :class="clientWidth > 750 ? 'flex0' : ''">
          <div class="register-detail">
            <h1>信息提交表单</h1>
            <el-form :model="paperForm" :rules="rules" ref="paperForm" class="paperForm">
              <el-form-item prop="topic">
                <div class="paper-form-label"><span class="paper-form-require">*</span> 论文题目</div>
                <el-input v-model.trim="paperForm.topic" maxlength="100" placeholder="请输入论文题目"></el-input>
              </el-form-item>
              <el-form-item prop="author">
                <div class="paper-form-label"><span class="paper-form-require">*</span> 第一作者姓名</div>
                <el-input v-model.trim="paperForm.author" maxlength="100" placeholder="请输入第一作者姓名"></el-input>
              </el-form-item>
              <el-form-item prop="company" class="author_input">
                <div class="paper-form-label"><span class="paper-form-require">*</span> 第一作者单位 <span
                    class="paper-form-small">（请填写全称）</span></div>
                <el-select v-model="paperForm.company" placeholder="请输入第一作者单位" ref="select" filterable remote
                  reserve-keyword :remote-method="remoteMethod" :loading="loading" @focus="clear" @hook:mounted="clear"
                  @visible-change="clear">
                  <el-option v-for="(item, index) in searchOptions" :key="index" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="phone" class="author_input">
                <div class="paper-form-label"><span class="paper-form-require">*</span> 电话<span
                    class="paper-form-small">（用于后续论文集相关事宜沟通）</span></div>
                <el-input v-model.trim="paperForm.phone" placeholder="请输入电话" @input="changePhone"></el-input>
              </el-form-item>
              <el-form-item prop="code" class="code_input" v-if="codeChangeShow">
                <div class="paper-form-label"><span class="paper-form-require">*</span> 验证码</div>
                <div class="flex2">
                  <el-input v-model.trim="paperForm.code" placeholder="请输入验证码" style="margin-right: 5px"
                    maxlength="6"></el-input>
                  <el-button class="getCode" v-if="codeShow" @click="getPhoneCode">获取验证码</el-button>
                  <el-button class="getCode" v-else disabled>{{ count }} 秒后重试</el-button>
                </div>
              </el-form-item>
              <el-form-item prop="email" class="form-item email_input author_input">
                <div class="paper-form-label"><span class="paper-form-require">*</span> 邮箱<span
                    class="paper-form-small">（用于后续论文集相关事宜沟通）</span></div>
                <el-input v-model.trim="paperForm.email" placeholder="请输入邮箱"></el-input>
              </el-form-item>
              <el-form-item prop="type">
                <div class="paper-form-label"><span class="paper-form-require">*</span> 投稿方向</div>
                <el-select v-model="paperForm.type" placeholder="请选择投稿方向" ref="select">
                  <el-option label="工程建设方向" value="工程建设方向"></el-option>
                  <el-option label="运营优化方向" value="运营优化方向"></el-option>
                  <el-option label="监测控制方向" value="监测控制方向"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="address">
                <div class="paper-form-label"><span class="paper-form-require">*</span> 通讯地址</div>
                <el-input v-model.trim="paperForm.address" maxlength="100" placeholder="请输入通讯地址"></el-input>
              </el-form-item>
              <el-form-item prop="way" class="way_input">
                <div class="paper-form-label paper-form-lineHeight1"><span class="paper-form-require">*</span>
                  是否为已发表的期刊<span class="paper-form-small">（包括同一篇文章多种语言发表)</span></div>
                <el-radio-group v-model="paperForm.way" @change="codetypechange">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <div v-if="releaseShow">
                <el-form-item prop="journalName">
                  <div class="paper-form-label paper-form-lineHeight2"><span class="paper-form-require">*</span>
                    请填写完整信息，如期刊名称、年份、语言、页数等</div>
                  <el-input v-model.trim="paperForm.journalName" maxlength="150" placeholder="请输入完整信息"></el-input>
                </el-form-item>
              </div>
              <el-form-item prop="fileUrl" class="word_input">
                <div class="paper-form-label"><span class="paper-form-require">*</span> 上传文件，仅限 word 文档的格式</div>
                <div class="flex2">
                  <el-input v-model.trim="paperForm.wordName" :readonly="true" placeholder="请上传 word 文档"
                    style="margin-right: 5px" maxlength="6"></el-input>
                  <el-upload :action="baseUrl + '/Files/UploadAzureBlob'" :before-upload="(file) => beforeFile(1, file)"
                    :on-success="(response, file, fileList) => successFile(1, response, file, fileList)">
                    <el-button size="small" type="info" :loading="loadings[0]"><i class="el-icon-upload2"></i>
                      上传</el-button>
                  </el-upload>
                </div>
              </el-form-item>
              <div class="privacy-text">
                <el-checkbox v-model="privacy" class="flex">
                  我已阅读，并知晓以下事项
                </el-checkbox>
                <div class="privacy-texts"><span>* </span>
                  我以作者的身份承诺对所提交论文具有著作权，不存在侵犯第三方的知识产权及其他合规问题，已征得历次期刊编辑的同意，允许转载、摘编，并同意授权 AVEVA 剑维软件将其收录于《2023 年度 AVEVA
                  剑维软件中国区工业数字化先锋论文集》并公开发表，包括线上及印刷等。</div>
                <div class="privacy-texts"><span>* </span> 我承诺自该稿件向 AVEVA 投递前的三十日内并未向报社、期刊、公众号等媒体平台投递该稿件。</div>
                <div class="privacy-texts"><span>* </span> 我承诺自该稿件向 AVEVA 投递后至 2023 年 11 月 30
                  日前不会向其他报社、期刊、公众号等媒体平台投递该稿件。</div>
              </div>
              <el-form-item class="flex0">
                <el-button type="info" size="medium" :loading="loading" @click="submitForm('paperForm')"
                  class="pc-register loginBtn">提 交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <div class="LoginAlert">
      <div v-if="showDialog" class="shade flex0">
        <div class="shade-section tc">
          <div class="shade-section-title" v-html="dialogContent"></div>
          <div class="shade-section-btn">
            <button type="button" class="xy-btn" v-if="btnShow" @click="goNext">确定</button>
          </div>
          <i class="el-icon-circle-close close-alert co-fff cur" v-if="closeShow" @click="closeDialog"></i>
        </div>
      </div>
    </div>

    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange" />

    <Footers v-if="paperShow"></Footers>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { SavePaper, getCode } from "@/api/register.js";
import { GetUserInfo } from "@/api/profile.js";
import shadeAlert from "@/components/shadeAlert/index.vue"
import { debounce, GetWxShares } from "@/utils/common";
import { GetCompanyList } from "@/api/Home.js";
import { ApiToken } from '@/utils/ApiToken'
import Headers from '@/components/header.vue';
import Footers from '@/components/footer.vue';

export default {
  data() {
    return {
      loading: false,
      paperShow: true, //展示页面
      sessionList: [],

      // !弹窗配置
      shadeConfig: {},
      baseUrl: ApiToken.baseApi,
      loadings: [false, false, false],
      loadingBtn: false,
      currentIndex: 0,
      tabObj: {},
      tabList: [
        {
          title: '工程建设方向',
          img: require('@/assets/PC/paper/tab_img1.png'),
          img_m: require('@/assets/PC/paper/tab_img_m1.png'),
          title1: '工艺模拟、工程设计、材料与施工管理、数字化交付等工程建设相关业务基于 AVEVA 以下产品（包含但不限于）的相关实践经验：',
          list1: [
            { title: '1.	AVEVA PRO/II Simulation', more1: '（原 PRO/II）' },
            { title: '2.	AVEVA Process Simulation', more1: '（原 SimCentral）' },
            { title: '3.	AVEVA E3D Design（E3D）/AVEVA Marine（AM）', more1: '' },
            { title: '4.	AVEVA Engineering', more1: '' },
            { title: '5.	AVEVA Diagrams/AVEVA Instrumentation/AVEVA Electrical', more1: '' },
            { title: '6.	AVEVA ERM', more1: '' },
            { title: '7.	AVEVA Asset Information Management', more1: '（原 AVEVA NET）', more2: '/AVEVA ISM/AVEVA Engage' },
            { title: '8.	AVEVA Point Cloud Manager', more1: '（原 LFM）' },
            { title: '9. AVEVA VISUAL FLARE', more1: '' },
            { title: '10. AVEVA PIPEPHASE', more1: '' }
          ],
          title2: '论文内容包含但不限于以下类别：',
          list2: [
            { title: '1. 产品技术与业务应用' },
            { title: '2. 实施方法与管理措施' },
            { title: '3. 二次开发与深化应用' },
            { title: '4. 工程企业数字化转型规划与实践' },
          ]
        },
        {
          title: '运营优化方向',
          img: require('@/assets/PC/paper/tab_img2.jpg'),
          img_m: require('@/assets/PC/paper/tab_img_m2.jpg'),
          title1: '在线优化、仿真培训、仿真模拟、资产管理、实时历史数据库、资产性能管理、先进控制、供应链管理、企业工作流应用等相关业务，基于 AVEVA 以下产品（包含但不限于）的相关实践经验：',
          list1: [
            { title: '1. AVEVA PI System', more1: '' },
            { title: '2.	AVEVA Asset Information Management', more1: '（原 AVEVA NET）', more2: '/AVEVA ISM/AVEVA Engage' },
            { title: '3.	AVEVA Predictive Analytics', more1: '（原 PRiSM）' },
            { title: '4.	AVEVA Asset Strategy Optimization', more1: '' },
            { title: '5.	AVEVA XR', more1: '（原 EYESIM）' },
            { title: '6.	AVEVA Unified Supply Chain', more1: '（原 Spiral Suite）' },
            { title: '7.	AVEVA Process Optimization', more1: '（原 ROMeo）' },
            { title: '8.	AVEVA Process Simulation', more1: '（原 SimCentral）' },
            { title: '9. AVEVA Dynamic Simulation', more1: '（原 DYNSIM）' },
            { title: '10. AVEVA APC', more1: '（原 Connoisseur, Advanced Process Control）' },
            { title: '11. AVEVA Work Tasks', more1: '（原 Skelta BPM）' }
          ],
          title2: '论文内容包含但不限于以下类别：',
          list2: [
            { title: '1. 产品技术与业务应用' },
            { title: '2. 实施方法与管理措施' },
            { title: '3. 二次开发与深化应用' },
            { title: '4. 运营企业数字化转型规划与实践' },
          ]
        },
        {
          title: '监测控制方向',
          img: require('@/assets/PC/paper/tab_img3.jpg'),
          img_m: require('@/assets/PC/paper/tab_img_m3.jpg'),
          title1: '涉及生产及运营管理系统的监视与控制，过程数据采集和存储以及过程数据的应用，操作员监视与图形系统的应用，边缘系统及物联网应用，制造执行系统中涉及到操作管理，质量管理，设备绩效，计划排产应用，行业应用系统的集成相关业务及应用创新。基于 AVEVA 以下产品（包含但不限于）的相关实践经验：',
          list1: [
            { title: '1.	AVEVA System Platform 和 OMI', more1: '（原 Wonderware System Platform）' },
            { title: '2.	AVEVA Historian 及 Client', more1: '（原 Wonderware Historian）' },
            { title: '3.	AVEVA InTouch HMI', more1: '（原 Wonderware InTouch）' },
            { title: '4.	AVEVA Edge HMI', more1: '（原 Wonderware InTouch Edge HMI）' },
            { title: '5.	AVEVA MES', more1: '（原 Wonderware MES）' },
            { title: '6.	AVEVA Plant SCADA', more1: '（原 Citect SCADA）' },
            { title: '7.	AVEVA Enterprise SCADA', more1: '（原 OASys SCADA）' },
            { title: '8.	AVEVA Reports for Operations', more1: '' },
            { title: '9. AVEVA Unified Operation Center', more1: '' },
          ],
          title2: '论文内容包含但不限于以下类别：',
          list2: [
            { title: '1. 产品技术与业务应用' },
            { title: '2. 实施方法与管理措施' },
            { title: '3. 二次开发与深化应用' },
            { title: '4. 运营企业数字化转型规划与实践' },
          ]
        }
      ],

      codeChangeShow: false,
      codeShow: true,                      // !获取验证码选中状态
      count: "",                           // !验证码状态
      releaseShow: true,

      showDialog: false,
      dialogContent: '',
      closeShow: false,
      btnShow: false,

      loading: false,                      // !登录loading
      privacy: false,                      // !隐私链接是否选中
      searchOptions: [],                   // !搜索公司数据
      paperForm: {
        topic: "",
        author: "",
        company: '',
        phone: "",                         // !电话
        code: "",
        email: "",                         // !邮箱
        type: "",
        address: "",
        way: "1",
        journalName: "",
        fileUrl: "",
        wordName: "",
      },
      comPhone: '',

      rules: {
        topic: [{ required: true, message: "请输入论文题目", trigger: "blur" }],
        author: [{ required: true, message: "请输入第一作者姓名", trigger: "blur" }],
        company: [{ required: true, message: "请输入第一作者单位", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入您的电话", trigger: "blur" },
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: "请输入正确的电话", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择投稿方向", trigger: "blur" }],
        address: [{ required: true, message: "请输入通讯地址", trigger: "blur" }],
        way: [{ required: true, message: "请选择是否为已发表的期刊", trigger: "blur" }],
        journalName: [{ required: true, message: "请输入完整信息", trigger: "blur" }],
        fileUrl: [{ required: true, message: "请上传 word 文档", trigger: "blur" }],
      },
    }
  },
  components: {
    shadeAlert,
    Headers,
    Footers
  },
  mounted() {
    const share = {
      title: '工业自动化先锋论文集',
      desc: '',
    };

    GetWxShares(share);
    this.tabObj = this.tabList[0]
  },
  methods: {
    // 切换tab
    clickTab(item, index) {
      if (this.currentIndex != index) {
        this.currentIndex = index
        this.tabObj = item
      }
    },
    // 点击确定
    goNext() {
      this.paperShow = true
      this.showDialog = false
      this.closeDialog()
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
    // 搜索公司
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.GetCompanyList(query);
        }, 200);
      }
    },
    // 获取公司列表
    GetCompanyList(key) {
      GetCompanyList({ keyword: key }).then(res => {
        this.searchOptions = res.data
      })
    },
    clear(async) {
      this.$nextTick(() => {
        if (!async) {
          // ios 手机有延迟问题
          setTimeout(() => {
            const { select } = this.$refs
            const input = select.$el.querySelector('.el-input__inner')
            input.removeAttribute('readonly')
          }, 200)
        }
      })
    },
    // 显示表单
    showForm() {
      // 登录限制
      if (!this.$store.state.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来提交论文吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: '已有剑盟账号，前往登录',
              link: '/Login?redirectUrl=' + this.$route.fullPath,
            },
            {
              text: '没有账号，立即注册',
              link: '/Register?redirectUrl=' + this.$route.fullPath,
            },
          ],
        };
        return false;
      }

      this.paperShow = false
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      GetUserInfo().then(res => {
        if (res.status == 1) {
          this.comPhone = res.data.phone
          if (res.data.surName || res.data.userName) {
            this.paperForm.author = res.data.surName + res.data.userName
          } else {
            this.paperForm.author = res.data.name
          }
          this.paperForm.phone = res.data.phone
          this.paperForm.email = res.data.email
          this.paperForm.company = res.data.company
          this.codeChangeShow = false
        }
      })
    },
    changePhone(val) {
      console.log(val)
      if (val != this.comPhone) {
        this.codeChangeShow = true
      } else {
        this.codeChangeShow = false
      }
    },
    // 选择是否期刊
    codetypechange(val) {
      if (val == '1') {
        this.releaseShow = true
      } else {
        this.releaseShow = false
        this.paperForm.journalName = ""
        this.paperForm.journalLang = ""
        this.paperForm.journalYear = ""
        this.paperForm.journalPage = ""
      }
    },
    // !点击获取验证码
    getPhoneCode() {
      const phone = this.paperForm.phone;
      if (!phone) {
        this.$toast({ message: "请先输入您的电话！" });
        return false;
        // } else if (!validator(phone,1)) {
        //   this.$toast({ message: "请输入正确的电话！" });
        //   return false;
      } else {
        debounce(() => {
          getCode({ phone: phone, type: 5, way: 'phone', isCheck: false }).then((res) => {
            if (res.status == 1) {
              this.$toast("发送成功！");
              const TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.codeShow = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.codeShow = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            } else {
              this.$toast(res.message);
            }
          });
        }, 1000)
      }
    },
    // !关闭登录指引弹窗
    closeAlertChange(state) {
      this.shadeConfig.show = state;
    },
    // !文件上传前的回调
    beforeFile(type, file) {
      if (file.size > 1 * 1024 * 1024 * 1024) {
        this.$toast("文件大小超出所能上传的最大限制（1GB）")
        return false;
      }
      const filetype = '.' + file.name.split('.')[file.name.split('.').length - 1];
      switch (type) {
        case 1:
          if (filetype === '.doc' || filetype === '.docx') {
            this.loadings.splice(0, 1, true);
          } else {
            this.$toast("上传文件格式与要求不符，请重新上传！");
            return false;
          }
          break;
        case 2:
          if (filetype === '.mp4' || filetype === '.zip') {
            this.loadings.splice(1, 1, true);
          } else {
            this.$toast("上传文件格式与要求不符，请重新上传！");
            return false;
          }
          break;
        case 3:
          if (filetype === '.jpg' || filetype === '.zip') {
            this.loadings.splice(2, 1, true);
          } else {
            this.$toast("上传文件格式与要求不符，请重新上传！");
            return false;
          }
          break;
      }
    },
    // !文件上传成功回调
    successFile(type, response) {
      if (response.status == 1) {
        this.$toast("上传成功！");
        switch (type) {
          case 1:
            this.paperForm.fileUrl = response.data.fileUrl;
            this.paperForm.wordName = response.data.fileName;
            this.loadings.splice(0, 1, false);
            break;
          case 2:
            this.paperForm.video = response.data.fileUrl;
            this.paperForm.videoName = response.data.fileName;
            this.loadings.splice(1, 1, false);
            break;
          case 3:
            this.paperForm.image = response.data.fileUrl;
            this.paperForm.imageName = response.data.fileName;
            this.loadings.splice(2, 1, false);
            break;
        }
      } else {
        this.loadings.splice(type - 1, 1, false);
        this.$toast(response.message);
      }
    },
    closeDialog() {
      this.showDialog = false
      this.$refs.paperForm.resetFields();
      this.privacy = false;
      this.loading = false;
      this.paperForm.fileUrl = ""
      this.paperForm.wordName = ""
      this.paperForm.way = "1"
      this.paperForm.code = "1"
      this.paperForm.journalName = ""
      this.releaseShow = true
      this.codeChangeShow = false
      this.codeShow = true
    },
    // !报名
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.privacy) {
            this.loading = true;
            const paperForm = JSON.parse(JSON.stringify(this.paperForm))
            delete paperForm.wordName
            delete paperForm.way

            console.log(paperForm)
            SavePaper(paperForm).then((res) => {
              console.log(res)
              if (res.status == 1) {
                // this.$toast.success({ message: "已完成！", duration: 1000 });
                this.showDialog = true
                this.dialogContent = '提交成功'
                this.closeShow = false
                this.btnShow = true
              } else {
                this.loading = false;
                this.$toast(res.message);
              }
            });
          } else {
            this.$alert("请先阅读并同意授权！", "提示", {
              confirmButtonClass: "el-button--mini",
              callback: (action) => { },
            });
            return false;
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  },
  computed: {
    ...mapState(['clientWidth', 'Module'])
  },
};
</script>
<style lang="scss">
.paper-form-label {
  font-size: 16px;
  color: #606266;

  .paper-form-require {
    color: red;
    font-size: 14px;
  }

  .paper-form-small {
    font-size: 13px;
  }
}

.wrap {
  .el-date-editor.el-input {
    width: 100%;
  }

  .el-date-editor .el-input__icon {
    line-height: 1;
  }
}
</style>
<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.wrap {
  width: 100%;
  padding-top: 60px;
  height: 100%;
  overflow: hidden;
  font-size: 30px;
}

.banner {
  width: 100%;
  height: auto;
  display: block;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.module1 {
  padding: 14px 0 50px;
}

.module2 {
  background-color: #F9F9F9;
  padding-bottom: 69px;
}

.module3 {
  width: 100%;
  height: 513px;
  background-image: url(../../assets/PC/paper/need_back.png);
  background-size: 100% 513px;
  background-repeat: no-repeat;

  .paper-title {
    color: #fff !important;
  }
}

.module4 {
  padding-bottom: 15px;
}

.module5 {
  padding-bottom: 64px;
}

.paper {
  width: 1200px;
  margin: 0 auto;

  .paper-title {
    padding: 49px 0 40px;
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    color: #363D42;
  }

  .paper-introduction {
    p {
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  .paper-btn {
    width: 180px;
    height: 45px;
    margin-top: 8px;
    background: #6b18a8;
    border: 0;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;

    span {
      font-size: 20px;
      color: #fff;
      margin-top: -2px;
    }

    img {
      width: 9px;
      margin-left: 20px;
    }
  }

  .paper-tab-box {
    display: flex;
    padding: 0 48px;
    border-bottom: 3px solid #6b18a8;

    .paper-tab {
      width: calc((100% - 20px) / 3);
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin-right: 20px;
      border-radius: 10px 10px 0px 0px;
      border: 1px solid #E4E4E4;
      border-bottom: none;
      background-color: #fff;
      color: #363D42;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    }

    .tab-active {
      background-color: #6b18a8;
      color: #fff;
      border: none;
      cursor: default;
    }
  }

  .paper-tab-content-box {
    background-color: #fff;
    box-shadow: 0 0 10px #CCC;
    padding: 41px;
    display: flex;
    align-items: flex-start;

    .paper-tab-content-left {
      width: 397px;

      img {
        width: 360px;
      }
    }

    .paper-tab-content-right {
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      color: #363D42;

      .paper-tab-content-right-title {
        font-weight: bold;
        line-height: 28px;
        font-size: 16px;
        margin-bottom: 10px;
      }

      .paper-tab-content-right-top {
        margin-top: 20px;
      }

      .paper-tab-content-right-list {
        span {
          line-height: 28px;
          font-size: 16px;
        }

        span:nth-child(2) {
          color: #738294;
          font-size: 14px;
        }
      }
    }
  }

  .paper-numer {
    width: 100%;
    height: 208px;
    background-image: url(../../assets/PC/paper/number.png);
    background-size: 986px 208px;
    background-repeat: no-repeat;
    background-position: top center;
    display: flex;

    div {
      width: 178px;
      color: #fff;
      font-size: 16px;
      line-height: 32px;
    }

    div:nth-child(1) {
      margin-left: 65px;
      margin-top: 90px;
    }

    div:nth-child(2) {
      margin-left: 45px;
      margin-top: 210px;
    }

    div:nth-child(3) {
      margin-left: 50px;
      margin-top: 90px;
    }

    div:nth-child(4) {
      margin-left: 45px;
      margin-top: 210px;
    }

    div:nth-child(5) {
      margin-left: 50px;
      margin-top: 90px;
    }
  }

  .paper-contribute {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 29px;
    background-color: #EFE1F9;

    img {
      width: 39px;
    }

    .paper-contribute-text {
      flex: 1;
      padding: 0 26px;
      color: #363D42;
      font-size: 16px;
      font-weight: bold;
      line-height: 32px;
    }
  }

  .paper-select-box {
    display: flex;
    padding-bottom: 20px;

    .paper-select {
      width: calc((100% - 20px) / 3);
      height: 149px;
      padding: 26px 20px 0;
      margin-right: 20px;
      font-size: 16px;
      line-height: 30px;
      background-color: #6b18a8;
      color: #fff;
      position: relative;
    }

    .paper-select::after {
      width: 0px;
      height: 0px;
      border-right: 12px solid transparent;
      border-top: 12px solid #6b18a8;
      border-bottom: 12px solid transparent;
      border-left: 12px solid transparent;
      display: block;
      content: '';
      position: absolute;
      bottom: -22px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }
  }

  .paper-order-box {
    padding: 0 40px;
    display: flex;

    .paper-order-line {
      border-bottom: 1px dashed #6B18A8;
      margin-bottom: 17px;
    }

    .paper-order-line:nth-child(1) {
      width: 128px;
    }

    .paper-order-line:nth-child(3) {
      width: 357px;
    }

    .paper-order-line:nth-child(5) {
      width: 357px;
    }

    .paper-order-line:nth-child(7) {
      width: 128px;
    }

    .paper-order-index {
      width: 34px;
      height: 34px;
      line-height: 34px;
      border-radius: 50%;
      text-align: center;
      background: #C530FC;
      font-size: 20px;
      margin: 0 5px;
      color: #fff;
    }
  }

  .paper-pdf-detail {
    padding: 22px 0 41px;
    font-size: 14px;
    text-align: center;

    span {
      color: #363D42;
    }

    a {
      color: #0084FF !important;
      text-decoration: underline;
    }
  }
}

.paper-footer {
  width: 100%;
  height: 109px;
  font-size: 14px;
  background-color: #363D42;
  color: #fff;
  line-height: 20px;
  vertical-align: middle;

  img {
    width: 20px;
    margin: 0 10px;
  }

  a {
    color: #0084FF !important;
    border-bottom: 1px solid #0084FF;
    line-height: 20px;
    margin-top: -2px;
  }
}

.register {
  .register-body {
    padding: 15px 0;
    min-height: calc(100vh - 83px)
  }

  .register-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  }

  .register-logo img {
    width: 589px
  }

  .register-detail {
    box-shadow: 0 0 10px #ccc
  }

  .privacy-text,
  .subscription,
  .privacy-text label {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .privacy-text /deep/ .el-checkbox__label {
    font-size: 16px;
  }

  .privacy-texts {
    font-size: 16px;
    color: #606266;
    margin-bottom: 0;
    line-height: 24px;

    span {
      color: red;
      margin-right: 6px;
    }
  }

  .el-checkbox__label {
    white-space: normal !important;
  }

  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #606266;
  }

  .privacy-text /deep/ .el-checkbox__inner,
  .subscription .el-checkbox__inner {
    margin-top: 3px;
  }

  .agrees {
    color: #606266;
  }

  .el-cascader {
    width: 100%;
  }
}

@media (min-width: 750px) {
  .el-form-item {
    margin-bottom: 20px;
    display: flex;

    /deep/ .el-form-item__label {
      width: 180px !important;
    }

    /deep/ .el-input__inner {
      width: 780px;
    }

  }

  .code_input {
    /deep/ .el-input__inner {
      width: 663px;
    }
  }

  .author_input {
    /deep/ .el-form-item__label {
      line-height: 20px !important;
    }
  }

  .word_input {
    /deep/ .el-form-item__label {
      line-height: 20px !important;
    }

    /deep/ .el-input__inner {
      width: 695px;
    }

    .el-button {
      width: 80px;
      height: 40px;
    }

    /deep/ .el-upload-list.el-upload-list--text {
      display: none;
    }
  }

  .privacy-text {
    margin-left: 0px;
  }

  .loginBtn {
    width: 200px;
    margin: 10px auto;
  }

  .register {
    background: #f7f7f7;

    .register-detail {
      padding: 30px 0;
      width: 850px;
      background: #fff;

      h1 {
        font-size: 20px;
        font-weight: bold;
        color: #363d42;
        line-height: 46px;
        text-align: center;
        margin-bottom: 15px;
      }

      .paperForm {
        width: 775px;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 750px) {
  .wrap {
    padding-top: vw(100);
  }

  .paper-form-lineHeight1 {
    line-height: vw(38);
    margin-bottom: vw(5);
    margin-top: vw(5);
  }

  .paper-form-lineHeight2 {
    line-height: vw(38);
    margin-bottom: vw(5);
  }

  .paper-form-label {
    font-size: 14px;
    color: #606266;

    .paper-form-require {
      color: red;
      font-size: 12px;
    }

    .paper-form-small {
      font-size: 12px;
    }
  }

  .banner {
    img {
      width: vw(750);
    }
  }

  .module1 {
    padding: 0 vw(35) vw(50);
  }

  .module2 {
    padding: 0 vw(35) vw(50);
  }

  .module3 {
    height: vw(756);
    background-image: url(../../assets/PC/paper/need_back_m.png);
    background-size: 100% vw(756);
    padding: 0 vw(35) vw(50);
  }

  .module4 {
    padding: 0 vw(35) 0;
  }

  .module5 {
    padding: 0 vw(35) vw(50);
  }

  .paper {
    width: 100%;

    .paper-title {
      padding: vw(50) 0 vw(37);
      font-size: vw(34);
    }

    .paper-introduction {
      p {
        font-size: vw(24);
        line-height: vw(38);
        margin-bottom: vw(20);
      }
    }

    .paper-btn {
      width: vw(220);
      height: vw(56);
      margin-top: vw(10);

      span {
        font-size: vw(26);
        color: #fff;
        margin-top: vw(-1);
      }

      img {
        width: vw(11);
        margin-left: vw(22);
      }
    }

    .paper-tab-box {
      padding: 0 vw(30);
      border-bottom: none;

      .paper-tab {
        width: calc((100% - 11px) / 3);
        height: vw(56);
        line-height: vw(56);
        margin-right: vw(11);
        border-radius: vw(10) vw(10) 0px 0px;
        font-size: vw(24);
      }
    }

    .paper-tab-content-box {
      box-shadow: none;
      border: 1px solid #6b18a8;
      padding: vw(35);
      flex-direction: column;

      .paper-tab-content-left {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .paper-tab-content-right {
        margin-left: 0;
        margin-top: vw(30);
        font-size: vw(24);

        .paper-tab-content-right-title {
          line-height: vw(38);
          margin-bottom: vw(15);
          font-size: vw(24);
        }

        .paper-tab-content-right-top {
          margin-top: vw(30);
        }

        .paper-tab-content-right-list {
          span {
            line-height: vw(38);
            font-size: vw(24);
          }

          span:nth-child(2) {
            font-size: vw(20);
          }
        }
      }
    }

    .paper-numer-m {
      .paper-numer-m-box:last-child {
        div {
          border: none
        }
      }

      .paper-numer-m-box {
        display: flex;
        align-items: flex-start;
        margin-bottom: vw(25);

        img {
          width: vw(60);
          margin-right: vw(33);
        }

        .paper-numer-m-text {
          width: 100%;
          margin-top: vw(-3);

          span {
            color: #fff;
            font-size: vw(24);
            line-height: vw(38);
          }

          div {
            width: 100%;
            margin-top: vw(25);
            border-bottom: 1px dashed rgba(249, 249, 249, 0.2);
          }
        }

        .paper-numer-m-top {
          padding-top: vw(15);

        }
      }

      .paper-numer-m-box-center {
        align-items: center;

        .paper-numer-m-text {
          margin-top: vw(0);
        }
      }
    }

    .paper-contribute {
      padding: 0;

      .paper-contribute-text-m {
        width: 100%;
        height: vw(229);
        ;
        background-image: url(../../assets/PC/paper/contribute_back.png);
        background-size: 100% vw(229);
        ;
        background-repeat: no-repeat;
        padding: vw(30) vw(26);
        flex: 1;

        p:last-child {
          margin-top: vw(20);
        }

        p {
          color: #363D42;
          font-size: vw(24);
          font-weight: bold;
          line-height: vw(38);
        }
      }
    }

    .paper-select-bigBox {
      display: flex;

      .paper-select-box {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        .paper-select {
          width: 100%;
          height: auto;
          padding: 0 vw(38);
          margin-right: 0;
          margin-bottom: vw(30);
          font-size: vw(24);
          line-height: vw(38);
          background-color: #fff;
          border: 1px solid #6B18A8;
          color: #363D42;
          display: flex;
          align-items: center;
        }

        .paper-select:nth-child(1) {
          height: vw(175);
        }

        .paper-select:nth-child(2) {
          height: vw(135);
        }

        .paper-select:nth-child(3) {
          height: vw(145);
        }

        .paper-select::before {
          width: 0px;
          height: 0px;
          border-right: vw(22) solid #fff;
          border-top: vw(22) solid transparent;
          border-bottom: vw(22) solid transparent;
          border-left: vw(22) solid transparent;
          display: block;
          content: '';
          position: absolute;
          left: vw(-40);
          top: 50%;
          transform: translateY(-50%);
          z-index: 12;
        }

        .paper-select::after {
          width: 0px;
          height: 0px;
          border-right: vw(20) solid #6b18a8;
          border-top: vw(20) solid transparent;
          border-bottom: vw(20) solid transparent;
          border-left: vw(20) solid transparent;
          display: block;
          content: '';
          position: absolute;
          left: vw(-40);
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
        }
      }

      .paper-order-box {
        margin-right: vw(30);
        padding: 0 vw(15) 0 0;
        flex-direction: column;
        order: -1;

        .paper-order-line {
          border-left: 1px dashed #6B18A8;
          margin-left: vw(19);
          margin-bottom: 0;
          border-bottom: none;
        }

        .paper-order-line:nth-child(1) {
          width: 0;
          height: vw(62);
        }

        .paper-order-line:nth-child(3) {
          width: 0;
          height: vw(132);
        }

        .paper-order-line:nth-child(5) {
          width: 0;
          height: vw(117);
        }

        .paper-order-line:nth-child(7) {
          width: 0;
          height: vw(50);
        }

        .paper-order-index {
          width: vw(42);
          height: vw(42);
          line-height: vw(42);
          font-size: vw(24);
          font-weight: bold;
          margin: vw(5) 0;
        }
      }
    }

    .paper-pdf-detail {
      padding: 0 0 vw(35);
      font-size: vw(24);
      text-align: left;

      a {
        display: inline-block;
        margin-top: vw(10);
      }
    }
  }

  .paper-footer {
    height: vw(224);
    font-size: vw(24);
    padding: 0 vw(35);
    line-height: vw(32);
    flex-direction: column;
    align-items: flex-start;

    div {
      margin-top: vw(10);
    }

    img {
      width: vw(28);
      margin: 0 vw(14) 0 0;
    }

    a {
      border-bottom: 1px solid #0084FF;
      line-height: 20px;
      margin-top: -2px;
    }

    .paper-footer-phone {
      margin-right: vw(20);
    }
  }

  .el-form-item {
    margin-bottom: 14px;
  }

  .loginBtn {
    width: vw(300);
    margin: 10px auto;
  }

  .register /deep/ .el-checkbox__label {
    white-space: normal !important;
  }

  .word_input {
    .el-button {
      width: 80px;
      height: vw(62);
    }

    /deep/ .el-upload-list.el-upload-list--text {
      display: none;
    }
  }

  .register {
    min-height: 100vh;
    background: #f7f7f7;

    .register-body {
      padding: 0;
    }

    .register-logo {
      margin: vw(60) 0;
      text-align: center
    }

    .register-logo img {
      width: vw(321)
    }

    .register-detail {
      padding: vw(38);
      background: #fff;
      margin: vw(38);
    }

    .privacy-text {
      margin-top: vw(40);
    }

    .privacy-text label a {
      font-size: 14px;
    }

    .privacy-text /deep/ .el-checkbox__label {
      font-size: 14px;
    }

    .privacy-texts {
      font-size: 14px;
      margin-bottom: 0;
      line-height: vw(44);
    }

    .register-detail h1 {
      font-size: vw(30);
      font-weight: bold;
      color: #363d42;
      line-height: vw(46);
      text-align: center;
      margin-bottom: vw(15);
    }
  }
}

// 弹窗样式
@media (min-width: 751px) {
  .LoginAlert {
    .shade-section {
      width: 450px;
      background: url('~@/assets/PC/InnovationContest/invite-friends.png') no-repeat;
      background-size: 100% 100%;
      padding: 110px 20px 40px 20px;
      position: relative;
      min-height: 300px
    }

    .shade-section-title {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 15px;
      font-family: '黑粗';
      font-weight: bold;

      a {
        font-size: 22px;
        line-height: 1.5;
        margin-bottom: 15px;
        font-family: '黑粗';
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .shade-section-btn {
      margin-top: 50px
    }

    .xy-btn {
      padding: 12px 20px;
      font-size: 14px;
      background: #6b18a8;
      color: #fff;
      display: inline-block;
      border: 0;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s
    }

    .xy-btn+.xy-btn {
      margin-left: 10px
    }

    .xy-btn:hover {
      opacity: 0.8
    }
  }
}

@media (max-width: 750px) {
  .LoginAlert {
    .shade-section {
      width: calc(100% - 20px);
      background: url('~@/assets/PC/InnovationContest/invite-friends.png') no-repeat;
      background-size: 100% 100%;
      padding: 90px 10px 40px 10px;
      position: relative;
      min-height: vw(300)
    }

    .shade-section-title {
      font-size: vw(40);
      line-height: 1.5;
      margin-bottom: 15px;
      font-family: '黑粗';
      font-weight: bold;

      a {
        font-size: vw(36);
        line-height: 1.5;
        margin-bottom: 15px;
        font-family: '黑粗';
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .shade-section-btn {
      margin-top: 20px
    }

    .xy-btn {
      padding: 9px 15px;
      font-size: 12px;
      background: #6b18a8;
      color: #fff;
      display: inline-block;
      border: 0;
      border-radius: 3px
    }

    .xy-btn+.xy-btn {
      margin-left: 5px
    }
  }
}
</style>
